/*
   Color Palette

   These colors should never be used in a component directly.
   Always assign them a functional variable name before use.

   Tool for color name lookup: http://chir.ag/projects/name-that-color
**/
/*
   Forwood Style Guide Color Palette

   Comments indicate legacy color variables to replace with Forwood Palette colors
**/
/*
   Functional variables

   Components should refer to their functional name rather than the color name.
   These should always refer to a palette color from above
**/
@media (max-width: 767px) {
  .hide-if-mobile {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hide-if-not-mobile {
    display: none !important;
  }
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.sm-icon {
  font-size: 12px;
}

.md-icon {
  font-size: 16px;
}

.lg-icon {
  font-size: 24px;
}

.has-error {
  color: #F52222;
}

/*
   Ant Design Overrides
**/
html {
  /*
     Base Layout:
     Layout, Header, Content, Footer
  **/
  /*
     Menu
  **/
  /*
     Tables
  **/
  /*
     Modal
  **/
  /*
    Row Data Display
  **/
  /*
     Table
  **/
  /*
     Buttons
  **/
  /*
     Divider
  **/
  /*
     Pagination
  **/
  /*
    Spinner
  **/
  /*
    Drawer
  **/
}
html h1, html h2, html h3, html h4, html h5, html h6 {
  font-family: Montserrat-Regular, Arial, Helvetica, sans-serif;
}
html .ant-layout {
  font-family: Montserrat-Regular, Arial, Helvetica, sans-serif;
}
html main.ant-layout-content {
  margin: 24px;
}
html .ant-menu {
  font-family: Montserrat-SemiBold, Arial, Helvetica, sans-serif;
}
html .ant-table {
  font-family: Montserrat-Regular, Arial, Helvetica, sans-serif;
}
html .ant-popover {
  font-family: Montserrat-Regular, Arial, Helvetica, sans-serif;
}
html .ant-modal {
  font-family: Montserrat-SemiBold, Arial, Helvetica, sans-serif;
}
html .ant-modal .ant-form-item {
  margin-bottom: 5px;
}
html .ant-modal .ant-form-item:first-child {
  margin-top: 0;
}
html .ant-alert {
  font-family: Montserrat-SemiBold, Arial, Helvetica, sans-serif;
}
html .ant-card {
  font-family: Montserrat-Regular, Arial, Helvetica, sans-serif;
}
html .ant-data-row {
  margin-bottom: 10px;
}
html .ant-table-thead tr th {
  padding: 14px 10px;
}
html button {
  font-family: Montserrat-Regular, Arial, Helvetica, sans-serif;
}
html .ant-btn-primary {
  background: #0173B4;
  border-color: #0173B4;
}
html .ant-btn-primary:hover {
  background: #17A7FE;
  border-color: #17A7FE;
}
html .ant-btn-secondary {
  background: #DCDCDC;
}
html .ant-divider.invisible {
  background: none;
  margin: 14px 0;
}
html .ant-form-item, html .ant-list, html .ant-input, html .ant-select, html .ant-select-dropdown, html .ant-select-tree, html .ant-dropdown {
  font-family: Montserrat-Regular, Arial, Helvetica, sans-serif;
}
@media (max-width: 576px) {
  html .ant-pagination-total-text {
    display: block;
  }
  html .ant-pagination-item,
  html .ant-pagination-prev {
    margin-right: 4px;
  }
}
html .ant-spin-text {
  font-family: Montserrat-Regular, Arial, Helvetica, sans-serif;
}
html .ant-drawer-title {
  font-family: Montserrat-Regular, Arial, Helvetica, sans-serif;
  font-size: 20px;
}
html .ant-drawer-wrapper-body {
  overflow: auto !important;
}
html .ant-checkbox-wrapper {
  font-family: Montserrat-Regular, Arial, Helvetica, sans-serif;
}

@font-face {
  font-family: Montserrat-Regular;
  src: url(/static/media/Montserrat-Regular.9c460951.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Montserrat-SemiBold;
  src: url(/static/media/Montserrat-SemiBold.c88cecbf.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Forwood-Regular;
  src: url(/static/media/FORWOOD_Regular.43493a08.ttf);
  font-weight: normal;
  font-style: normal;
}
#root {
  font-family: Montserrat-Regular, Arial, Helvetica, sans-serif;
}

/*
   Custom Form Label
**/
.custom-form-label {
  color: #656565;
  font-family: Montserrat-Regular, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.33333;
  cursor: default;
}

.custom-field-display-box {
  cursor: default;
  font-family: inherit;
  border: 1px solid #DCDCDC;
  border-radius: 3px;
  min-width: 0;
  min-height: 27px;
  width: 100%;
  background: #ffffff;
  display: inline-block;
  text-align: start;
  margin-bottom: 5px;
  padding: 3px 5px 3px 5px;
  word-wrap: break-word;
}
